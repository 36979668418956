import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import type {
  TagExperience,
  TagIdentity,
  TagSpecialty,
  Trainer,
} from "@trainwell/types";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import type { ThemeMode } from "src/theme/themeModes";
import CheckpointSettings from "./CheckpointSettings";
import NotificationsSettings from "./NotificationsSettings";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export const specialtiesData: PartialRecord<TagSpecialty, string> = {
  weight_loss: "Weight loss",
  eat_healthy: "Eating healthier",
  mobility_flexibility: "Mobility & flexibility",
  mental_wellness: "Mental wellness",
  reduce_fatigue: "Reducing fatigue",
  quality_of_life: "Quality of life",
  strength: "Strength training",
  sports: "Sport performance",
  general_health: "General health",
};

export const experienceData: PartialRecord<TagExperience, string> = {
  older_clients: "Older clients",
  pre_postnatal: "Pre/postnatal mothers",
  obesity: "Obesity",
  adhd: "ADHD",
  anxiety: "Anxiety",
  depression: "Depression",
  body_dysmorphia: "Body dysmorphia",
  auto_immunity: "Auto-immunity",
};

export const identityData: PartialRecord<TagIdentity, string> = {
  male: "Male",
  female: "Female",
  non_binary: "Non-binary",
  language_spanish: "Spanish speaking",
};

export default function SettingsPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const realCoach = useAppSelector((state) => state.trainer.trainer);
  const dashThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_dash ?? "light",
  ) as ThemeMode;
  const chatThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_chat ?? "light",
  ) as ThemeMode;

  function changeDashTheme(newDashThemeMode: ThemeMode) {
    if (!realCoach) {
      return;
    }

    dispatch(
      updateTrainer({
        trainer_id: realCoach.trainer_id,
        settings: {
          ...realCoach.settings,
          theme_dash: newDashThemeMode,
        },
      }),
    );
  }

  function changeChatTheme(newChatThemeMode: ThemeMode) {
    if (!realCoach) {
      return;
    }

    dispatch(
      updateTrainer({
        trainer_id: realCoach.trainer_id,
        settings: {
          ...realCoach!.settings,
          theme_chat: newChatThemeMode,
        },
      }),
    );
  }

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box
        sx={{
          p: 3,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Settings
        </Typography>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Appearence
          </Typography>
          <Typography variant="h6">Dash theme</Typography>
          <ToggleButtonGroup
            color="primary"
            value={dashThemeMode}
            exclusive
            onChange={(event, newDashThemeMode) => {
              if (newDashThemeMode) {
                changeDashTheme(newDashThemeMode);
              }
            }}
            sx={{ mb: 2 }}
          >
            <ToggleButton value="light" sx={{ py: 0.5 }}>
              Light
            </ToggleButton>
            <ToggleButton value="light_soft" sx={{ py: 0.5 }}>
              Light soft
            </ToggleButton>
            <ToggleButton value="dark" sx={{ py: 0.5 }}>
              Dark soft
            </ToggleButton>
            <ToggleButton value="auto" sx={{ py: 0.5 }}>
              Auto
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="h6">Chat theme</Typography>
          <ToggleButtonGroup
            color="primary"
            value={chatThemeMode}
            exclusive
            onChange={(event, newChatThemeMode) => {
              if (newChatThemeMode) {
                changeChatTheme(newChatThemeMode);
              }
            }}
          >
            <ToggleButton value="light" sx={{ py: 0.5 }}>
              Light
            </ToggleButton>
            <ToggleButton value="light_soft" sx={{ py: 0.5 }}>
              Light soft
            </ToggleButton>
            <ToggleButton value="dark" sx={{ py: 0.5 }}>
              Dark soft
            </ToggleButton>
            <ToggleButton value="auto" sx={{ py: 0.5 }}>
              Auto
            </ToggleButton>
          </ToggleButtonGroup>
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Workouts
          </Typography>
          <Stack direction={"column"} spacing={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.enable_estimated_max === true}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.enable_estimated_max": event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Show estimated max for every set"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={!trainer.settings.disable_smart_orm}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.disable_smart_orm": !event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Use default weight when no set history is found"
              />
            </FormGroup>
          </Stack>
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Slack messages 💬
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Control what kind of slack messages you want to receive.
          </Typography>
          <Stack direction={"column"} spacing={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      trainer.settings.send_client_call_starting_slack_message
                    }
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_client_call_starting_slack_message":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Pre-call reminders"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message right before a client call of any kind is
                about to start.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      trainer.settings.send_onboarding_period_ended_alerts
                    }
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_onboarding_period_ended_alerts":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Post-onboarding call reminders"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message an hour after an onboarding call was
                scheduled to end if you forgot to mark the client as having been
                onboarded, rescheduled the call, or missed the call.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      trainer.settings.send_onboarding_call_recording_reminders
                    }
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_onboarding_call_recording_reminders":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Weekly onboarding call recording reminders"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message Wednesday evening if you haven&apos;t
                recorded an onboarding call yet. Only sends if you&apos;ve
                onboarded at least two clients that week.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.send_client_call_event_message}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_client_call_event_message":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Call updates"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message whenever a client schedules, updates, or
                deletes a call of any kind.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      trainer.settings.send_missed_and_late_to_call_alerts
                    }
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_missed_and_late_to_call_alerts":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Daily call summaries"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message at the end of each day summarizing calls of
                any kind that you missed or were late to.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.send_client_capacity_updates}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_client_capacity_updates":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Client capacity changes"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message when your client capacity changes.
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.send_client_ramp_up_state_updates}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_client_ramp_up_state_updates":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Ramp up stage changes"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack message when your client ramp up stage changes (eg
                when your ramp up speed goes from 10 to 5 clients per week, or
                when you reach a new capacity and it&apos;s time to start a
                review period).
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.send_vip_alerts_to_slack}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.send_vip_alerts_to_slack":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="VIP alerts"
              />
              <FormHelperText sx={{ mt: 0 }}>
                Get a slack DM when a VIP does a workout or sends a message.
              </FormHelperText>
            </FormGroup>
          </Stack>
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Chat
          </Typography>
          <Stack direction={"column"} spacing={1}>
            <FormControl sx={{ maxWidth: 300 }} size="small">
              <InputLabel>Default chat mode</InputLabel>
              <Select
                value={trainer.settings.chat_mode}
                label="Default chat mode"
                onChange={(event) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "settings.chat_mode": event.target
                        .value as Trainer["settings"]["chat_mode"],
                    }),
                  );
                }}
              >
                <MenuItem value={"big_left"}>Big chat</MenuItem>
                <MenuItem value={"drag_minimized"}>Small chat</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.chat_questions_includes_dms}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.chat_questions_includes_dms":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Include DMs in the Questions section"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.hide_action_item_count}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.hide_action_item_count":
                            event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Hide action item count until check-in"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={!trainer.settings.hide_habit_day_summaries}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.hide_habit_day_summaries":
                            !event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Show habit day summaries in chat"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={trainer.settings.play_chat_sound}
                    onChange={(event) => {
                      dispatch(
                        updateTrainer({
                          trainer_id: trainer.trainer_id,
                          // @ts-expect-error
                          "settings.play_chat_sound": event.target.checked,
                        }),
                      );
                    }}
                  />
                }
                label="Play sound for new messages"
              />
            </FormGroup>
            <FormControl sx={{ maxWidth: 300 }} size="small">
              <InputLabel>Notification sound</InputLabel>
              <Select
                value={trainer.settings.chat_sound}
                label="Notification sound"
                onChange={(event) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "settings.chat_sound": event.target
                        .value as Trainer["settings"]["chat_sound"],
                    }),
                  );

                  const audio = new Audio(
                    event.target.value === "got-mail"
                      ? "/sounds/got-mail.mp3"
                      : event.target.value === "click"
                        ? "/sounds/click.mp3"
                        : "/sounds/ding.mp3",
                  );
                  audio.play();
                }}
              >
                <MenuItem value={"default"}>Default</MenuItem>
                <MenuItem value={"click"}>Click</MenuItem>
                <MenuItem value={"got-mail"}>AOL you&apos;ve got mail</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <CheckpointSettings />
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <NotificationsSettings />
        </Card>
      </Box>
    </Box>
  );
}
