import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type {
  HabitSummaryMessage as HabitSummaryMessageType,
  HabitTaskMessage,
  Message,
  MessagePhaseExplanation,
  NotificationMessage as NotificationMessageType,
} from "@trainwell/types";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useState, type ReactNode } from "react";
import CreateActionItemDialog from "src/components/misc/CreateActionItemDialog";
import SubmitTicketDialog from "src/components/misc/SubmitTicketDialog";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import {
  deleteMessage,
  selectChatById,
  sendMessageAsSms,
} from "src/slices/chatSlice";
import Reactions from "../Reactions";
import SendAsEmailDialog from "../SendAsEmailDialog";
import HabitMessage from "./HabitMessage";
import HabitsMissedMessage from "./HabitsMissedMessage";
import ImageMessage from "./ImageMessage";
import { MealLogReportMessage } from "./MealLogReportMessage";
import NotificationMessage from "./NotificationMessage";
import PhaseExplanationMessage from "./PhaseExplanationMessage";
import TextMessage from "./TextMessage";
import VideoMessage from "./VideoMessage";

type Props = {
  message: Message;
  isFromMe: boolean;
  showName?: boolean;
  disableInteractive?: boolean;
  combineBottom: boolean;
  isFromOtherCoach?: boolean;
  condensed?: boolean;
  forceSmsDisabled?: boolean;
};

export default function MessageBubble({
  message,
  isFromMe,
  showName,
  disableInteractive,
  combineBottom,
  isFromOtherCoach,
  condensed,
  forceSmsDisabled,
}: Props) {
  let content: ReactNode = <Box />;

  if (message.type === "text") {
    content = (
      <MessageWrapper
        showReminder
        showDelete={isFromMe}
        showReport={!isFromMe}
        showReaction
        showSms={isFromMe}
        forceSmsDisabled={forceSmsDisabled}
        showEmail={isFromMe}
        showName={showName}
        isFromMe={isFromMe}
        message={message}
        disableInteractive={disableInteractive}
        condensed={condensed}
      >
        <TextMessage
          text={message.text!}
          isFromMe={isFromMe}
          combineBottom={combineBottom}
          isFromOtherCoach={isFromOtherCoach}
        />
      </MessageWrapper>
    );
  } else if (message.type === "notification") {
    content = (
      <NotificationMessage message={message as NotificationMessageType} />
    );
  } else if (message.type === "moment") {
    content = null;
  } else if (message.type === "video") {
    content = (
      <MessageWrapper
        showReaction
        showDelete={isFromMe}
        showName={showName}
        isFromMe={isFromMe}
        message={message}
        disableInteractive={disableInteractive}
        showSms={isFromMe}
        condensed={condensed}
        forceSmsDisabled={forceSmsDisabled}
      >
        <VideoMessage
          width={message.width}
          height={message.height}
          url={message.media_url!}
          thumbnailUrl={message.thumbnail_url!}
          isFromMe={isFromMe}
          combineBottom={combineBottom}
        />
      </MessageWrapper>
    );
  } else if (message.type === "phase_explanation") {
    content = (
      <MessageWrapper
        isFromMe={isFromMe}
        message={message}
        disableInteractive={disableInteractive}
        condensed={condensed}
        showDelete={isFromMe}
      >
        <PhaseExplanationMessage
          message={message as MessagePhaseExplanation}
          combineBottom={combineBottom}
        />
      </MessageWrapper>
    );
  } else if (message.type === "meal_log_report") {
    content = (
      <MessageWrapper
        isFromMe={isFromMe}
        message={message}
        disableInteractive={disableInteractive}
        condensed={condensed}
        showDelete={isFromMe}
      >
        <MealLogReportMessage message={message} combineBottom={combineBottom} />
      </MessageWrapper>
    );
  } else if (message.type === "image") {
    content = (
      <MessageWrapper
        showReaction
        showDelete={isFromMe}
        showName={showName}
        isFromMe={isFromMe}
        message={message}
        showReport={!isFromMe}
        disableInteractive={disableInteractive}
        showSms={isFromMe}
        condensed={condensed}
        forceSmsDisabled={forceSmsDisabled}
      >
        <ImageMessage
          width={message.width}
          height={message.height}
          url={message.media_url!}
          isFromMe={isFromMe}
          combineBottom={combineBottom}
        />
      </MessageWrapper>
    );
  } else if (message.type === "habit_task_log") {
    content = (
      <MessageWrapper
        showReaction
        showReport
        isFromMe={isFromMe}
        message={message}
        condensed={condensed}
      >
        <HabitMessage
          message={message as HabitTaskMessage}
          combineBottom={combineBottom}
          disableInteractive={disableInteractive}
        />
        {(message as HabitTaskMessage).all_tasks_completed && (
          <Typography variant="h3" sx={{ textAlign: "center", py: 1 }}>
            Finished all habits! 🎉
          </Typography>
        )}
      </MessageWrapper>
    );
  } else if (message.type === "daily_habit_summary") {
    content = (
      <HabitsMissedMessage message={message as HabitSummaryMessageType} />
    );
  }

  return (
    <Box
      sx={{
        pb: combineBottom
          ? message.reactions && message.reactions.length > 0
            ? 1.5
            : 0.25
          : 2,
      }}
    >
      {content}
    </Box>
  );
}

type ButtonWrapperProps = {
  message: Message;
  isFromMe?: boolean;
  showName?: boolean;
  showReminder?: boolean;
  showDelete?: boolean;
  showReport?: boolean;
  showSms?: boolean;
  showEmail?: boolean;
  disableTooltip?: boolean;
  forceSmsDisabled?: boolean;
  showReaction?: boolean;
  disableInteractive?: boolean;
  children: React.ReactNode;
  condensed?: boolean;
};

function MessageWrapper({
  message,
  isFromMe,
  showName,
  showReminder = false,
  showDelete = false,
  showReport = false,
  showSms = false,
  showEmail = false,
  showReaction = false,
  disableTooltip = false,
  forceSmsDisabled = false,
  disableInteractive,
  children,
  condensed,
}: ButtonWrapperProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [actionitemDialogOpen, setActionItemDialogOpen] = useState(false);
  const [submitTicketDialogOpen, setSubmitTicketDialogOpen] = useState(false);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const chatId = useAppSelector((state) => state.chat.selectedChatId);
  const chatName = useAppSelector(
    (state) => selectChatById(state, chatId ?? "")?.clientName,
  );
  const [sendAsEmailMessage, setSendAsEmailMessage] = useState<Message | null>(
    null,
  );
  const [isHovering, setIsHovering] = useState(false);
  const [reactionsAnchorEl, setReactionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const dateString = format(
    new Date(message.send_date),
    "iiii, MMM d, yyyy 'at' h:mm a",
  );

  let readDateString: string | undefined;
  if (message.read_date) {
    readDateString = format(
      new Date(message.read_date),
      "iiii, MMM d, yyyy 'at' h:mm a",
    );
  }

  const headshotUrl =
    message.from_headshot_url ||
    trainerNames.find((trainer) => trainer.trainer_id === message.from_id)
      ?.headshot_url;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isFromMe ? "flex-end" : "flex-start",
          mr: isFromMe ? 1 : undefined,
          ml: isFromMe ? undefined : 1,
        }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        {showName && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
            {headshotUrl && (
              <img
                style={{
                  borderRadius: "8px",
                  marginRight: "8px",
                }}
                src={headshotUrl}
                width={16}
                height={16}
                alt="headshot"
              />
            )}
            <Typography variant="overline">
              {message.from_first_name && message.from_last_name
                ? `${message.from_first_name} ${message.from_last_name}`
                : getTrainerName(message.from_id, trainerNames)}
            </Typography>
          </Box>
        )}
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: isFromMe ? "row-reverse" : "row",
            }}
          >
            <Tooltip
              title={
                disableTooltip ? (
                  ""
                ) : (
                  <>
                    {dateString}
                    <Typography sx={{ fontSize: 10 }}>
                      {readDateString ? `Read: ${readDateString}` : "Unread"}
                    </Typography>
                  </>
                )
              }
              disableInteractive
              placement={"left"}
              enterDelay={300}
            >
              <Box sx={{ position: "relative" }}>
                {children}
                {message.reactions && (
                  <Stack
                    direction={"row"}
                    sx={{
                      position: "absolute",
                      bottom: -12,
                      right: -4,
                      backgroundColor: (theme) =>
                        !isFromMe
                          ? theme.palette.primary.main
                          : theme.palette.background.default,
                      borderRadius: 3,
                      minWidth: "19px",
                      justifyContent: "center",
                      px: 0.5,
                      border: 2,
                      borderColor: (theme) =>
                        theme.palette.backgroundSecondary.main,
                    }}
                    spacing={0.5}
                  >
                    {message.reactions.map((reaction) => (
                      <Tooltip
                        key={reaction.reaction}
                        title={
                          trainerNames.find(
                            (c) => c.trainer_id === reaction.user_id,
                          )?.full_name ?? chatName
                        }
                        disableInteractive
                      >
                        <Typography variant="body2">
                          {reaction.reaction === "2764"
                            ? "\u2764\ufe0f"
                            : String.fromCodePoint(
                                parseInt(reaction.reaction, 16),
                              )}
                        </Typography>
                      </Tooltip>
                    ))}
                  </Stack>
                )}
              </Box>
            </Tooltip>
            <Stack
              direction={isFromMe ? "row-reverse" : "row"}
              alignItems={"center"}
              sx={{
                ml: isFromMe ? undefined : 0.5,
                mr: isFromMe ? 0.5 : undefined,
                opacity:
                  (isHovering ||
                    Boolean(moreAnchorEl) ||
                    Boolean(reactionsAnchorEl)) &&
                  !disableInteractive
                    ? undefined
                    : 0,
                pointerEvents:
                  (isHovering ||
                    Boolean(moreAnchorEl) ||
                    Boolean(reactionsAnchorEl)) &&
                  !disableInteractive
                    ? undefined
                    : "none",
              }}
            >
              {showReaction && (
                <Tooltip title="React" disableInteractive placement="top">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setReactionsAnchorEl(event.currentTarget);
                    }}
                  >
                    <SentimentSatisfiedRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="More" disableInteractive placement="top">
                <IconButton
                  size="small"
                  onClick={(event) => {
                    setMoreAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVertRoundedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Box>
        {/* @ts-expect-error */}
        {(message.send_state === "sending_sms" ||
          // @ts-expect-error
          message.send_state === "sending_email") && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
            <CircularProgress size={12} sx={{ mr: 1 }} />
            <Typography variant="overline">
              Sending as {/* @ts-expect-error */}
              {message.send_state === "sending_sms" ? "SMS" : "email"}
            </Typography>
          </Box>
        )}
        {/* @ts-expect-error */}
        {message.send_state === "error" && (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.error.main, mb: 0.5 }}
          >
            Error sending
          </Typography>
        )}
        {message.date_sms_sent && (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary, mb: 0.5 }}
          >
            Sent as SMS{" "}
            {format(new Date(message.date_sms_sent), "MMM d, h:mm aaa")}
          </Typography>
        )}
        {message.date_email_sent && (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary, mb: 0.5 }}
          >
            Sent as email{" "}
            {format(new Date(message.date_email_sent), "MMM d, h:mm aaa")}
          </Typography>
        )}
        {message.source === "sms" && (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary, mb: 0.5 }}
          >
            Sent by SMS
          </Typography>
        )}
      </Box>
      {showEmail && (
        <SendAsEmailDialog
          open={sendAsEmailMessage !== null}
          onClose={() => {
            setSendAsEmailMessage(null);
          }}
          message={sendAsEmailMessage}
        />
      )}
      {showReport && (
        <SubmitTicketDialog
          open={submitTicketDialogOpen}
          onClose={() => {
            setSubmitTicketDialogOpen(false);
          }}
          attachedMessage={
            message.habit_task?.workout_log_id ? undefined : message
          }
          userId={message.from_id}
          logId={
            (message as HabitTaskMessage)?.habit_task?.workout_log_id ??
            undefined
          }
        />
      )}
      {showReminder && (
        <CreateActionItemDialog
          open={actionitemDialogOpen}
          onClose={() => {
            setActionItemDialogOpen(false);
          }}
          defaultMessage={message.text!}
          defaultUserId={isFromMe ? message.to_id : message.from_id}
        />
      )}
      {showReaction && (
        <Menu
          open={Boolean(reactionsAnchorEl)}
          anchorEl={reactionsAnchorEl}
          onClose={() => {
            setReactionsAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {showReaction && (
            <Reactions
              message={message}
              sx={{ mx: 1 }}
              onChoose={() => {
                setReactionsAnchorEl(null);
              }}
            />
          )}
        </Menu>
      )}
      <Menu
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {showReminder && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              setActionItemDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <AssignmentRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Create action item" />
          </MenuItem>
        )}
        {showReport && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              setSubmitTicketDialogOpen(true);
            }}
            disabled={message.issue_report_id ? true : false}
          >
            <ListItemIcon>
              <SupportRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                message.issue_report_id
                  ? "Ticket sent"
                  : "Create support ticket"
              }
            />
          </MenuItem>
        )}
        {showSms && (
          <Tooltip
            disableInteractive
            placement="left"
            title={forceSmsDisabled ? "Client has SMS disabled" : ""}
          >
            <span>
              <MenuItem
                onClick={() => {
                  setMoreAnchorEl(null);

                  if (chatId) {
                    dispatch(
                      sendMessageAsSms({
                        messageId: message.message_id,
                        chatId: chatId,
                      }),
                    )
                      .unwrap()
                      .catch(() => {
                        enqueueSnackbar("Error sending sms", {
                          variant: "error",
                        });
                      });
                  }
                }}
                disabled={Boolean(
                  forceSmsDisabled ||
                    message.date_sms_sent ||
                    // @ts-expect-error
                    message.send_state === "sending_sms",
                )}
              >
                <ListItemIcon>
                  <SmsRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={message.date_sms_sent ? "SMS sent" : "Send as SMS"}
                />
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {showEmail && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              setSendAsEmailMessage(message);
            }}
            disabled={Boolean(
              // @ts-expect-error
              message.date_email_sent || message.send_state === "sending_email",
            )}
          >
            <ListItemIcon>
              <EmailRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={message.date_email_sent ? "Email sent" : "Send as Email"}
            />
          </MenuItem>
        )}
        {showDelete && (
          <Box>
            <Divider sx={{ mb: 1, mt: 2 }} />
          </Box>
        )}
        {showDelete && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              dispatch(deleteMessage(message.message_id));
            }}
          >
            <ListItemIcon>
              <DeleteRoundedIcon
                sx={{ color: (theme) => theme.palette.error.main }}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: (theme) => theme.palette.error.main }}
              primary="Delete"
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
