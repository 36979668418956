import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import type { WeekPlan } from "@trainwell/types";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { addWeeks, startOfWeek } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppSelector } from "src/hooks/stateHooks";
import { getZeroedOutUtcDate } from "src/lib/date";
import { api } from "src/lib/trainwellApi";
import { selectAllHabitPlans } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  userId: string;
  open: boolean;
  onClose: (sent: boolean) => void;
};

export function PhaseExplanationDialog({ userId, open, onClose }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const theme = useTheme();
  const [explanationData, setExplanationData] = useState<null | {
    videoUrl: string;
    thumbnailUrl: string;
    width: number;
    height: number;
  }>(null);
  const workoutIds = useAppSelector((state) => {
    const weekPlans = selectAllHabitPlans(state);

    if (!client || !client.account.dashboard.date_onboarded) {
      return [];
    }

    const onboardingWeekDate = getZeroedOutUtcDate(
      startOfWeek(client.account.dashboard.date_onboarded),
    );
    const nextWeekDate = getZeroedOutUtcDate(addWeeks(onboardingWeekDate, 1));

    const onboardingWeekPlan = weekPlans.find(
      (plan) => plan.date === onboardingWeekDate,
    );
    const nextWeekPlan = weekPlans.find((plan) => plan.date === nextWeekDate);

    const weeks = [onboardingWeekPlan, nextWeekPlan].filter(
      Boolean,
    ) as WeekPlan[];

    const workoutIds = weeks
      .map((week) => {
        const habitWeek = week.habit_weeks.find(
          (week) =>
            week.movement_type === "copilot_workout" &&
            week.type === "movement",
        );

        if (!habitWeek) {
          return [];
        }

        return (habitWeek.anchored_workout_days ?? [])
          .flat()
          .filter((day) => day !== null);
      })
      .flat();

    return [...new Set(workoutIds)];
  });
  const [isUplaoding, setIsUploading] = useState(false);
  const [sending, setSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", "video/*"],
      },
      onBeforeFileAdded: () => {
        setIsUploading(true);

        return true;
      },
    })
      .use(Webcam, {
        modes: ["video-audio"],
        videoConstraints: {
          facingMode: "user",
          width: { min: 480, max: 720, ideal: 480 },
          height: { min: 480, max: 720, ideal: 480 },
        },
        showRecordingLength: true,
        mobileNativeCamera: true,
        preferredVideoMimeType: "video/mp4",
      })
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "chat-media",
          },
        },
        waitForEncoding: true,
      })
      .on("complete", () => {
        // uppy.cancelAll();
        setIsUploading(false);
        // closeMediaUpload();
        // dispatch(setMediaUploadUi("hide"));
      })
      .on("transloadit:complete", (assembly) => {
        console.log("Uppy: upload success", assembly);

        if (
          "videos" in assembly.results &&
          assembly.results.videos.length &&
          assembly.results.videos[0].ssl_url &&
          "thumbnails" in assembly.results &&
          assembly.results.thumbnails.length &&
          assembly.results.thumbnails[0].ssl_url
        ) {
          const videoResult = assembly.results.videos[0];
          const thumbnailResult = assembly.results.thumbnails[0];

          setExplanationData({
            videoUrl: videoResult.ssl_url,
            thumbnailUrl: thumbnailResult.ssl_url,
            width: videoResult.meta.width,
            height: videoResult.meta.height,
          });
        }
      }),
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Send a phase explanation</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Record a video to create more excitement around your client&apos;s
          first phase and give them more context around how/why it&apos;s a good
          fit for them. This will be shown to them before they first open their
          workouts.
        </DialogContentText>
        <Dashboard
          uppy={uppy}
          plugins={["Webcam"]}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          height={400}
          doneButtonHandler={() => {
            onClose(false);
          }}
          theme={theme.palette.mode}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose(false);
          }}
          disabled={isUplaoding}
        >
          Don&apos;t send right now
        </Button>
        <LoadingButton
          variant="contained"
          loading={sending}
          startIcon={<SendRoundedIcon />}
          onClick={async () => {
            if (!explanationData) {
              return;
            }

            setSending(true);

            api.messages
              .sendPhaseExplanation({
                trainerId: trainer!.trainer_id,
                userId: userId,
                videoUrl: explanationData.videoUrl,
                thumbnailUrl: explanationData.thumbnailUrl,
                workoutIds: workoutIds,
                videoWidth: explanationData.width,
                videoHeight: explanationData.height,
              })
              .then(() => {
                onClose(true);
              })
              .catch((e) => {
                Sentry.captureException(e);

                enqueueSnackbar({
                  message: "Failed to send phase explanation",
                  variant: "error",
                });

                setSending(false);
              });
          }}
          disabled={!explanationData || isUplaoding}
        >
          Send explanation
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
