import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import VolumeDownRoundedIcon from "@mui/icons-material/VolumeDownRounded";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  debounce,
} from "@mui/material";
import { useCallback, useState } from "react";
import { shallowEqual } from "react-redux";
import SmartEditSwitch from "src/components/misc/SmartEditSwitch";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";

type Props = {
  userId: string;
  defaultExpanded?: boolean;
  freeFloating?: boolean;
};

export default function SettingsPanel({
  userId,
  defaultExpanded = false,
  freeFloating = false,
}: Props) {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.settings
        : undefined,
    shallowEqual,
  );
  const ownsSmartWatch = useAppSelector((state) =>
    state.client.client?.user_id === userId
      ? state.client.client.owns_smart_watch
      : undefined,
  );
  const devicePlatform = useAppSelector((state) =>
    state.client.client?.user_id === userId
      ? state.client.client.device_platform
      : undefined,
  );
  const [volume, setVolume] = useState(settings?.voice_prompt_volume ?? 100);

  const debouncedUpdateVolume = useCallback(
    debounce((newVolume: number) => {
      dispatch(
        updateClient({
          user_id: userId,
          // @ts-expect-error
          "settings.voice_prompt_volume": newVolume,
        }),
      );

      console.log("Save volume");
    }, 500),
    [],
  );

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">⚙️ App settings</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box sx={{ p: 1 }}>
          <Stack direction={"column"} spacing={2}>
            {devicePlatform === "ios" && (
              <Stack direction={"column"} spacing={1}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Watch and haptic
                </Typography>
                {ownsSmartWatch && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ToggleButtonGroup
                      color="primary"
                      value={settings?.watch_right_wrist ? "right" : "left"}
                      exclusive
                      onChange={(event, value) => {
                        dispatch(
                          updateClient({
                            user_id: userId,
                            // @ts-expect-error
                            "settings.watch_right_wrist":
                              value === "right" ? true : false,
                          }),
                        );
                      }}
                      size="small"
                    >
                      <ToggleButton sx={{ py: 0.25 }} value="left">
                        L
                      </ToggleButton>
                      <ToggleButton sx={{ py: 0.25 }} value="right">
                        R
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Typography sx={{ ml: 1 }}>Watch wrist location</Typography>
                  </Box>
                )}
                <SmartEditSwitch
                  label="Enable haptic feedback"
                  value={!settings?.haptics_disabled}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.haptics_disabled": !newValue,
                      }),
                    );
                  }}
                />
                <SmartEditSwitch
                  label="Enable 10s timer warning"
                  value={!settings?.ten_seconds_disabled}
                  disabled={settings?.haptics_disabled}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.ten_seconds_disabled": !newValue,
                      }),
                    );
                  }}
                />
              </Stack>
            )}
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>Voice prompts</Typography>
              <SmartEditSwitch
                label="Enable voice prompts"
                value={!settings?.voice_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.voice_disabled": !newValue,
                    }),
                  );
                }}
              />
              <Box sx={{ pb: 1 }}>
                <Typography>Volume</Typography>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <VolumeDownRoundedIcon />
                  <Slider
                    aria-label="Volume"
                    value={volume}
                    onChange={(event, newVolume) => {
                      setVolume(newVolume as number);
                      debouncedUpdateVolume(newVolume as number);
                    }}
                    marks={[
                      { value: 0, label: "Quiet" },
                      { value: 100, label: "Loud" },
                    ]}
                  />
                  <VolumeUpRoundedIcon />
                </Stack>
              </Box>
              <SmartEditSwitch
                label="Timed exercise feedback"
                subLabel={`"30 seconds left..."`}
                value={!settings?.voice_preference_timed_exercise_disabled}
                disabled={settings?.voice_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.voice_preference_timed_exercise_disabled":
                        !newValue,
                    }),
                  );
                }}
              />
              <SmartEditSwitch
                label="Rest ended feedback"
                subLabel={`"It's time for..."`}
                value={!settings?.voice_preference_rest_ended_disabled}
                disabled={settings?.voice_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.voice_preference_rest_ended_disabled":
                        !newValue,
                    }),
                  );
                }}
              />
              {devicePlatform === "ios" && (
                <SmartEditSwitch
                  label="Live feedback"
                  subLabel={`"Don't bend your elbows..."`}
                  value={!settings?.voice_preference_post_set_disabled}
                  disabled={settings?.voice_disabled}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.voice_preference_post_set_disabled":
                          !newValue,
                      }),
                    );
                  }}
                />
              )}
              <SmartEditSwitch
                label="Set feedback prompts"
                subLabel={`"How difficult was that set..."`}
                value={!settings?.voice_preference_feedback_request_disabled}
                disabled={settings?.voice_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.voice_preference_feedback_request_disabled":
                        !newValue,
                    }),
                  );
                }}
              />
            </Stack>
            {devicePlatform === "ios" && (
              <Stack direction={"column"} spacing={1}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Apple health
                </Typography>
                <SmartEditSwitch
                  label="Read from Apple Health"
                  value={!settings?.read_workouts_disabled}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.read_workouts_disabled": !newValue,
                      }),
                    );
                  }}
                />
                <SmartEditSwitch
                  label="Save trainwell workouts in Apple Health"
                  value={!settings?.write_workouts_disabled}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.write_workouts_disabled": !newValue,
                      }),
                    );
                  }}
                />
              </Stack>
            )}
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                Consistency & streaks
              </Typography>
              <SmartEditSwitch
                label="Consistency mode & streaks"
                subLabel="Encourages completing workouts on the exact day they are scheduled by enabling workout streaks and streak freezes"
                value={!settings?.consistency_mode_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.consistency_mode_disabled": !newValue,
                    }),
                  );
                }}
              />
              <SmartEditSwitch
                label="Streak save notifications"
                subLabel="These notifications remind you to complete your missed wourkout on the last day to save your streak"
                value={!settings?.streak_loss_alerts_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.streak_loss_alerts_disabled": !newValue,
                    }),
                  );
                }}
                disabled={settings?.consistency_mode_disabled}
              />
            </Stack>
            {devicePlatform === "ios" && (
              <Stack direction={"column"} spacing={1}>
                <Typography sx={{ fontWeight: "bold" }}>Group chats</Typography>
                <SmartEditSwitch
                  label="Share trainwell workouts"
                  value={settings?.share_internal_movement_habits ?? false}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.share_internal_movement_habits": newValue,
                      }),
                    );
                  }}
                />
                <SmartEditSwitch
                  label="Share imported activities"
                  value={settings?.share_external_movement_habits ?? false}
                  onSave={(newValue) => {
                    dispatch(
                      updateClient({
                        user_id: userId,
                        // @ts-expect-error
                        "settings.share_external_movement_habits": newValue,
                      }),
                    );
                  }}
                />
              </Stack>
            )}
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                Push notifications
              </Typography>
              <SmartEditSwitch
                label="Upcoming workout reminders"
                subLabel="These notifications remind you that you have a workout today"
                value={!settings?.upcoming_workout_alerts_disabled}
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.upcoming_workout_alerts_disabled": !newValue,
                    }),
                  );
                }}
              />
              <SmartEditSwitch
                label="Progress logging reminders"
                subLabel="Get reminders to measure progress based on your logging schedule"
                value={
                  !settings?.progress_metrics?.progress_notifications_disabled
                }
                onSave={(newValue) => {
                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.progress_metrics.progress_notifications_disabled":
                        !newValue,
                    }),
                  );
                }}
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "bold" }}>Other</Typography>
              <SmartEditSwitch
                label="Hide calories"
                value={settings?.hide_calories ?? false}
                onSave={(newValue) => {
                  console.log(newValue);

                  dispatch(
                    updateClient({
                      user_id: userId,
                      // @ts-expect-error
                      "settings.hide_calories": newValue,
                    }),
                  );
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
