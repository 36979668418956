import { Outlet } from "react-router-dom";
import LoadDataHelper from "src/components/app/LoadDataHelper";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function AuthRequired() {
  const isAdmin = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.is_admin,
  );
  const exercisesLoaded = useAppSelector(
    (state) => state.app.exerciseStatus === "succeeded",
  );
  const authenticated = useAppSelector((state) => state.trainer.authenticated);

  const coachExists = isAdmin !== undefined;

  if (!coachExists) {
    return <LoadingPage message="Loading trainer" />;
  }

  if (!authenticated) {
    return <LoadingPage message="Authenticating" />;
  }

  return (
    <>
      {exercisesLoaded ? (
        <Outlet />
      ) : (
        <LoadingPage message="Loading exercises" />
      )}
      <LoadDataHelper />
    </>
  );
}
